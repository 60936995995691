import { AnimatePresence, motion } from 'framer-motion'
import { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import SVG from 'react-inlinesvg'
import { usePopper } from 'react-popper'

import { useOnClickOutside } from '@/lib/hooks'
import PropTypes from '@/lib/propTypes'
import { hexWithAlpha } from '@/lib/utils'

import {
	FieldColorContainer,
	FieldColorDropdown,
	FieldColorIndicator,
	FieldColorLabel,
	FieldColorSample,
	FieldColorToggler,
	FieldColorWrapper,
} from './FieldColor.styles'
import FieldColorPicker, { FieldColorPickerWithAlpha } from './FieldColorPicker'

const FieldColor = ({
	name,
	register,
	error,
	defaultValue,
	placeholder = 'Select...',
	disabled,
	trigger,
	setValue,
	withAlpha = false,
}) => {
	const wrapperRef = useRef(null)
	const dropdownRef = useRef(null)
	const [isOpen, setIsOpen] = useState(false)
	const [color, setColor] = useState(defaultValue)
	const [referenceElement, setReferenceElement] = useState(null)
	const [popperElement, setPopperElement] = useState(null)

	const { styles, attributes } = usePopper(referenceElement, popperElement, {
		strategy: 'absolute',
		placement: 'bottom-start',
		modifiers: [
			{
				name: 'offset',
				options: {
					offset: [0, 5],
				},
			},
		],
	})

	useOnClickOutside(dropdownRef, () => setIsOpen(false))

	const handleTogglerClick = (e) => {
		if (!disabled) {
			setIsOpen(!isOpen)
		}
		e.stopPropagation()
		e.preventDefault()
	}

	const handleChange = (color, _e) => {
		const colorHex = withAlpha ? hexWithAlpha(color.hex, color.rgb.a) : color.hex
		setColor(color)

		if (trigger) trigger(name)
		if (setValue) setValue(name, colorHex, { shouldValidate: true, shouldDirty: true })
	}

	useEffect(() => {
		if (trigger) trigger(name)
		if (setValue) setValue(name, color?.hex || color)
	}, [])

	let colorHex = color
	if (color && color.hex && color.rgb) {
		colorHex = withAlpha ? hexWithAlpha(color.hex, color.rgb.a) : color.hex
	}

	return (
		<FieldColorWrapper
			{...register(name)}
			error={error}
			open={isOpen}
			empty={!color}
			ref={wrapperRef}
			disabled={disabled}
		>
			<FieldColorContainer ref={setReferenceElement}>
				<FieldColorToggler disabled={disabled} onClick={handleTogglerClick}>
					<FieldColorSample color={colorHex}>
						<SVG src={'/assets/icons/drop.svg'} />
					</FieldColorSample>
					<FieldColorLabel muted={placeholder && !color}>
						{colorHex || placeholder}
					</FieldColorLabel>
					<FieldColorIndicator>
						<SVG src={'/assets/icons/fill.svg'} />
					</FieldColorIndicator>
				</FieldColorToggler>
				{createPortal(
					<AnimatePresence mode="wait">
						{isOpen && (
							<motion.div
								ref={dropdownRef}
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								exit={{ opacity: 0 }}
								transition={{ duration: 0.15 }}
							>
								<FieldColorDropdown
									style={{
										...styles.popper,
										width: `${wrapperRef.current?.clientWidth}px`,
									}}
									{...attributes.popper}
									ref={setPopperElement}
								>
									{withAlpha ? (
										<FieldColorPickerWithAlpha
											color={defaultValue}
											onChange={handleChange}
										/>
									) : (
										<FieldColorPicker
											color={defaultValue}
											onChange={handleChange}
										/>
									)}
								</FieldColorDropdown>
							</motion.div>
						)}
					</AnimatePresence>,
					document.querySelector('#popups'),
				)}
			</FieldColorContainer>
		</FieldColorWrapper>
	)
}

FieldColor.propTypes = {
	error: PropTypes.bool,
	disabled: PropTypes.bool,
	defaultValue: PropTypes.string,
	name: PropTypes.string,
	placeholder: PropTypes.string,
	trigger: PropTypes.func,
	register: PropTypes.func,
	setValue: PropTypes.func,
}

export default FieldColor
