import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import { isNil } from 'lodash-es'

import Button from '@/components/Button'
import FieldSelect from '@/components/FieldSelect'
import FieldText from '@/components/FieldText'
import FormField from '@/components/FormField'
import { Col, Row } from '@/components/Grid'
import Loader from '@/components/Loader'
import {
	ShareVideobotActions,
	ShareVideobotConfigWrapper,
	ShareVideobotDisplayAdPreviewWrapper,
	ShareVideobotSection,
} from '@/features/Videobot/components/ShareVideobot/ShareVideobot.styles'
import { useShareModal } from '@/features/Videobot/hooks/useShareModal'
import Error from '@/layout/Error'
import { BotStatusType, SlideCategoryType } from '@/api/videobot.schemas'
import { useReadBotById, useReadBotWidgetById } from '@/api/videobot'
import { useVideobotEditor } from '@/features/Videobot/hooks/videobot_editor'
import { ControlledFieldToggle } from '@/components/field_toggle/field_toggle.component'

import { VideobotIframe } from './VideobotIframe'
import { LANGUAGES, createEmbedUrl } from './sharedOptions'

const PLACEMENTS = [
	{
		label: 'videobot:sharePage:placementTypes.doubleMpu',
		id: 'doubleMpu',
		width: 300,
		height: 600,
	},
	{
		label: 'videobot:sharePage:placementTypes.mpu',
		id: 'mpu',
		width: 300,
		height: 250,
	},
	{
		label: 'videobot:sharePage:placementTypes.interstitial',
		id: 'interstitial',
		width: 700,
		height: 400,
	},
	{
		label: 'videobot:sharePage:placementTypes.billboard',
		id: 'billboard',
		width: 970,
		height: 250,
	},
]

const ShareVideobotDisplayAdsPresentation = () => {
	const [t] = useTranslation(['videobot'])
	const {
		register,
		control,
		setValue,
		formState: { errors, isSubmitting },
		watch,
		getValues,
	} = useVideobotEditor()
	const { setModalContent, openModal } = useShareModal()

	const videobot = getValues()
	const watchSlides = watch('slides')

	const hasIntroSlide =
		Boolean(watchSlides?.length) &&
		watchSlides.some((slide) => slide.category === SlideCategoryType.INTRO)

	const introSlide =
		videobot.slides?.find((slide) => slide.category === SlideCategoryType.INTRO) ||
		videobot.slides?.[0]

	const defaultThumbnailSlide = videobot.adSettings?.thumbnail?.slide ?? introSlide?.id
	const thumbnailSlide = videobot.slides?.find(
		(slide) => slide.id === videobot.adSettings.thumbnail?.slide,
	)

	const maxTimestamp = thumbnailSlide?.duration ?? introSlide?.duration

	const lang = videobot.adSettings?.language || 'en'
	const thumbnail = !isNil(videobot.adSettings.thumbnail?.slide)
		? `${thumbnailSlide?.id ?? introSlide.id}:${videobot.adSettings.thumbnail.time || '0'}`
		: null
	const url = createEmbedUrl(videobot.publicId, {
		lang,
		thumbnail,
		click_tag: videobot.adSettings?.clickTag,
		impression_tag: videobot.adSettings?.impressionTag,
		is_display_ads: true,
	})

	const placement =
		PLACEMENTS.find((item) => item.id === videobot.adSettings?.placement) ?? PLACEMENTS[0]

	const embedCode = `
<!-- ${t(placement.label)} -->
<div style="width: ${placement.width}px; height: ${placement.height}px;">
<iframe src="${url}" id="videobot-iframe" frameborder="0" width="${placement.width}" height="${placement.height}" style="border-radius: 0px;" scrolling="no"></iframe>
</div>
<script src="https://videobot.com/embed/vb_ads.js"></script>
`

	useEffect(() => {
		setModalContent({
			title: t('videobot:sharePage.embedDisplayAds'),
			code: embedCode,
		})
	}, [embedCode])

	const handleShowClick = async (event) => {
		event.preventDefault()
		if (!hasIntroSlide) {
			toast.warning(t('videobot:sharePage.noSlides'))
			return
		}
		openModal(true)
	}

	return (
		<React.Fragment>
			<ShareVideobotConfigWrapper>
				<div className="flex flex-1 flex-col overflow-auto">
					<ShareVideobotSection>
						<h4>{t('videobot:sharePage.placement')}</h4>
						<Row>
							<Col>
								<FieldSelect
									register={register}
									placeholder={t(PLACEMENTS[0].label)}
									name="adSettings.placement"
									type="text"
									error={errors.adSettings?.placement}
									required
									options={PLACEMENTS.map((item) => ({
										value: item.id,
										label: t(item.label),
										item,
									}))}
									setValue={setValue}
									size="large"
									defaultValue={
										videobot.adSettings?.placement ?? PLACEMENTS[0].id
									}
								/>
							</Col>
						</Row>
					</ShareVideobotSection>
					<ShareVideobotSection>
						<h4>{t('videobot:sharePage.horizontalDesktopLayout')}</h4>
						<Row>
							<Col>
								<ControlledFieldToggle
									control={control}
									name="adSettings.horizontalDesktopLayout"
								/>
							</Col>
						</Row>
					</ShareVideobotSection>

					<ShareVideobotSection>
						<h4>{t('videobot:sharePage.adTracking')}</h4>
						<Row>
							<Col>
								<FormField label={t('videobot:sharePage.clickTag')}>
									<FieldText
										register={register}
										setValue={setValue}
										name="adSettings.clickTag"
										type="text"
										placeholder="https://ad.example.com/tracking"
										error={errors.adSettings?.clickTag}
										size="large"
										rules={{
											setValueAs: (value) => (value === '' ? null : value),
										}}
									/>
								</FormField>
							</Col>
						</Row>
						<Row>
							<Col>
								<FormField label={t('videobot:sharePage.impressionTag')}>
									<FieldText
										register={register}
										setValue={setValue}
										name="adSettings.impressionTag"
										type="text"
										placeholder="https://ad.example.com/tracking"
										error={errors.adSettings?.impressionTag}
										size="large"
										rules={{
											setValueAs: (value) => (value === '' ? null : value),
										}}
									/>
								</FormField>
							</Col>
						</Row>
					</ShareVideobotSection>

					<ShareVideobotSection>
						<h4>{t('videobot:sharePage.options')}</h4>
						<Row>
							<Col>
								<FormField label={t('videobot:sharePage.language')}>
									<FieldSelect
										register={register}
										placeholder={LANGUAGES[0].name}
										name="adSettings.language"
										type="text"
										error={errors.adSettings?.language}
										required
										options={LANGUAGES.map((item) => ({
											value: item.id,
											label: item.name,
											item,
										}))}
										setValue={setValue}
										size="large"
										defaultValue={videobot.adSettings?.language || 'en'}
									/>
								</FormField>
							</Col>
						</Row>
					</ShareVideobotSection>

					<ShareVideobotSection>
						<h4>{t('videobot:sharePage.thumbnail')}</h4>
						<Row>
							<Col sm={6} xxs={12}>
								<FormField label={t('videobot:sharePage.slide')}>
									<FieldSelect
										register={register}
										placeholder={introSlide?.name ?? ''}
										name="adSettings.thumbnail.slide"
										error={errors.adSettings?.thumbnail?.slide}
										options={videobot.slides?.map((item) => ({
											value: item.id,
											label: item.name,
											item,
										}))}
										setValue={setValue}
										defaultValue={defaultThumbnailSlide}
										size="large"
									/>
								</FormField>
							</Col>

							<Col sm={6} xxs={12}>
								<FormField label={t('videobot:sharePage.timestamp')}>
									<FieldText
										register={register}
										placeholder="0"
										name="adSettings.thumbnail.time"
										type="number"
										size="large"
										error={errors.adSettings?.thumbnail?.time}
										defaultValue={videobot.adSettings?.thumbnail?.time || 0}
										rules={{
											valueAsNumber: true,
											min: {
												value: 0,
												message: t('validation:min', { value: 0 }),
											},
											max: {
												value: maxTimestamp,
												message: t('validation:max', {
													value: maxTimestamp,
												}),
											},
										}}
									/>
								</FormField>
							</Col>
						</Row>
					</ShareVideobotSection>
					<ShareVideobotSection>
						<h4>{t('videobot:sharePage.functionality')}</h4>
						<Row>
							<Col>
								<FormField label={t('videobot:sharePage.autoplayOnScroll')}>
									<ControlledFieldToggle
										control={control}
										name="adSettings.autoplayOnScroll"
									/>
								</FormField>
							</Col>
						</Row>
					</ShareVideobotSection>
				</div>

				<ShareVideobotActions>
					<Button
						onClick={handleShowClick}
						disabled={videobot.status !== BotStatusType.Published || isSubmitting}
						iconPrepend="code"
					>
						{t('videobot:sharePage.showCode')}
					</Button>
				</ShareVideobotActions>
			</ShareVideobotConfigWrapper>

			<ShareVideobotDisplayAdPreviewWrapper>
				<VideobotIframe
					src="/preview/current?preview=true"
					videobot={videobot}
					variant="exact"
					style={{ width: `${placement.width}px`, height: `${placement.height}px` }}
				/>
			</ShareVideobotDisplayAdPreviewWrapper>
		</React.Fragment>
	)
}

export const ShareVideobotDisplayAds = () => {
	const { id } = useParams()
	const { data: videobot, isError: isErrorVideobot } = useReadBotById(id)
	const { data: videobotEmbed, isError: isErrorVideobotEmbed } = useReadBotWidgetById(id)

	const isLoading = !videobot || !videobotEmbed
	const isError = isErrorVideobot || isErrorVideobotEmbed

	return (
		<React.Fragment>
			<ShareVideobotDisplayAdsPresentation />
			{isLoading && <Loader cover isLoading />}
			{isError && <Error background="body" />}
		</React.Fragment>
	)
}
