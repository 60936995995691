import { capitalize } from 'lodash-es'
import React, { Fragment, useEffect, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useFieldArray, useWatch } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import styled from 'styled-components'
import TagPicker from 'rsuite/TagPicker'
import { toast } from 'react-toastify'

import { FetchError } from '@/fetcher'
import FieldAvatar from '@/components/FieldAvatar'
import FieldColor from '@/components/FieldColor'
import FieldSelect from '@/components/FieldSelect'
import FieldText from '@/components/FieldText'
import FormField from '@/components/FormField'
import FormRow from '@/components/FormRow'
import FormSection from '@/components/FormSection/FormSection'
import Button from '@/components/Button'
import { Col, Row } from '@/components/Grid'
import Loader from '@/components/Loader'
import { TextArea } from '@/components/TextArea/text_area'
import Error from '@/layout/Error'
import { LANGS } from '@/lib/constants'
import { Font } from '@/lib/mixins'
import { useCurrentAccount } from '@/modules/session/auth.store'
import {
	useReadAllTags,
	useCreateTag,
	getReadAllTagsQueryKey,
	useReadAllBots,
	useReadBotById,
	useReadAccountById,
} from '@/api/videobot'
import { useFeatureFlags } from '@/modules/session/global.hook'
import { FeatureType, GAConsentMode } from '@/api/videobot.schemas'
import { useVideobotEditor } from '@/features/Videobot/hooks/videobot_editor'
import {
	ControlledFieldToggle,
	FieldToggle,
} from '@/components/field_toggle/field_toggle.component'

// TODO: Why base 15px?
const VideoBotButtonPreview = styled.button`
	${Font('14px')}
	line-height: 16px;
	cursor: pointer;

	padding: 0 4rem;
	height: 3rem;
	border-radius: 23px;
	border: 0;

	color: ${(props) => props.textColor};
	background-color: ${(props) => props.backgroundColor};
`

const VideoBotButtonPreviewLabel = styled.label`
	visibility: hidden;
	display: block;
`

const CUSTOM_CSS_PLACEHOLDER = `
.videobot {
  font-family: 'Open Sans';
}

.options > button {
  background-color: #ffffff;
}
`.trim()

const GAConsentGrantedMode = {
	on: GAConsentMode.granted,
	off: GAConsentMode.denied,
}

const VideobotSettingsFormPresentation = ({ videobot, allBots }) => {
	const { t } = useTranslation(['videobot', 'widget'])
	const activeAccount = useCurrentAccount()
	const { data: accountData } = useReadAccountById(activeAccount.id)
	const {
		register,
		setValue,
		trigger,
		formState: { errors },
		control,
		watch,
	} = useVideobotEditor()
	const {
		fields: urlMapFields,
		append: appendUrlMap,
		remove: removeUrlMap,
	} = useFieldArray({ control, name: 'urlMapsList' })
	const { data: featureFlags } = useFeatureFlags({ account_id: videobot.accountId })

	const data = watch()
	const isGoogleTrackingEnabled = watch('isGoogleTrackingEnabled')
	const metaTrackingSettings = watch('metaTrackingSettings')
	const isMetaTrackingEnabled = !!metaTrackingSettings && metaTrackingSettings.enabled
	const textColor = watch('theme.textColor')
	const buttonBackgroundColor = watch('theme.backgroundColor')
	const formData = useWatch({ control })

	const isWidgetCoverHorizontal = watch('isHorizontalCoverEnabled')
	const aspectRatioSlider = isWidgetCoverHorizontal ? 40 / 21 : 0.525

	const [searchQuery, setSearchQuery] = useState('')

	const { data: tags } = useReadAllTags({
		account_id: activeAccount.id,
		size: 10,
		query: searchQuery,
	})

	const { mutate: createTag } = useCreateTag()
	const queryClient = useQueryClient()
	const [isAddingTag, setIsAddingTag] = useState(false)
	const newTagName = watch('newTagName')

	const addNewTag = () => {
		if (newTagName && newTagName.trim() !== '') {
			createTag(
				{ data: { name: newTagName, account_id: activeAccount.id } },
				{
					onSuccess: () => {
						setValue('newTagName', '')
						queryClient.invalidateQueries(
							getReadAllTagsQueryKey({ account_id: activeAccount.id, size: 100 }),
						)
						toast.success('New tag created')
					},
					onError: (error) => {
						if (error instanceof FetchError && error.statusCode === 400) {
							const errorMessage = error.payload.detail || 'Creating tag failed'

							toast.error(errorMessage)
						} else {
							toast.error('Creating tag failed')
						}
					},
				},
			)
		}
	}

	const ACCESS_CONTROL = [
		{
			id: 'Public',
			name: t('videobot:botFields.privacy.public'),
		},
		{
			id: 'Private',
			name: t('videobot:botFields.privacy.private'),
		},
	]

	useEffect(() => {
		if (data && data.theme) {
			register('theme.delayButtons', {
				setValueAs: (value) => (value === '' ? null : Number.parseInt(value, 10)),
			})
		}
	}, [register, setValue, data.theme])

	const isUrlMapEnabled = featureFlags?.includes(`dashboard:${FeatureType.url_map}`)
	const urlMapAppendHandler = (event) => {
		event.preventDefault()
		appendUrlMap({ url: '', botId: '' })
	}

	const urlMapRemoveHandler = (index) => (event) => {
		event.preventDefault()
		removeUrlMap(index)
	}

	const tagOptions = tags?.records.map((tag) => ({ label: tag.name, value: tag.name })) ?? []

	const defaultTagValues = formData.tagsList?.map((tag) => tag.name || tag)

	const isCompanyProfileFlagged = featureFlags?.includes(
		`dashboard:${FeatureType.company_profile}`,
	)

	const isHideShareButtonFlagged = featureFlags?.includes(
		`dashboard:${FeatureType.hide_share_button}`,
	)

	const isHideWatermarkFlagged = featureFlags?.includes(`dashboard:${FeatureType.hide_watermark}`)

	const isGradientEnabled = data.theme?.isGradientEnabled ?? true

	return (
		<Fragment>
			<FormSection
				title={t('videobot:settingsPage.settingsTitle')}
				description={t('videobot:settingsPage.settingsDescription')}
			>
				<FormRow
					title={t('videobot:settingsPage.accessControl')}
					description={
						<Trans i18nKey="videobot:settingsPage.accessControlDescription">
							<p>
								<i>Visible to Everyone</i> setting permits all team members to view
								and edit the Videobot.
							</p>
							<p>
								<i>Hidden from Some Members</i>, allows the Videobot to be accessed
								and managed by you, Owner, and Admins, excluding other member level
								users.
							</p>
						</Trans>
					}
				>
					<Row>
						<Col sm={6} xxs={12}>
							<FormField label={t('videobot:settingsPage.accessControl')}>
								<FieldSelect
									register={register}
									placeholder={t('videobot:settingsPage.accessControl')}
									name="privacy"
									type="text"
									error={errors.privacy}
									required
									options={ACCESS_CONTROL.map((item) => ({
										value: item.id,
										label: item.name,
										item,
									}))}
									setValue={setValue}
									defaultValue={data.privacy}
								/>
							</FormField>
						</Col>
					</Row>
				</FormRow>

				<FormRow
					title={t('videobot:settingsPage.businessName')}
					description={t('videobot:settingsPage.businessNameDescription')}
				>
					<Row>
						<Col sm={6} xxs={12}>
							<FormField label={t('videobot:settingsPage.businessName')}>
								<FieldText
									register={register}
									placeholder={t('videobot:settingsPage.businessName')}
									name="companyName"
									type="text"
									error={errors.companyName}
									required
								/>
							</FormField>
						</Col>
					</Row>
				</FormRow>

				<FormRow
					title={t('videobot:settingsPage.seo')}
					description={t('videobot:settingsPage.seoDescription')}
				>
					<Row>
						<Col sm={6} xxs={12}>
							<FormField label={t('videobot:settingsPage.seoTitleField')}>
								<FieldText
									register={register}
									placeholder={t('videobot:settingsPage.seoTitleField')}
									name="seoMeta.title"
									type="text"
									error={errors.seoMeta?.title}
								/>
							</FormField>
						</Col>
					</Row>
					<Row>
						<Col sm={6} xxs={12}>
							<FormField label={t('videobot:settingsPage.seoDescriptionField')}>
								<TextArea
									rows={4}
									placeholder="Internet used to be text - now it's VIDEO!"
									{...register('seoMeta.description')}
								/>
							</FormField>
						</Col>
					</Row>
					<Row>
						<Col lg={4} sm={6} xxs={12}>
							<ControlledFieldToggle
								control={control}
								name="seoMeta.isIndexingDisabled"
							>
								{t('videobot:settingsPage.disableIndexing')}
							</ControlledFieldToggle>
						</Col>
					</Row>
				</FormRow>

				<FormRow
					title={t('videobot:settingsPage.globalColours')}
					description={t('videobot:settingsPage.globalColoursDescription')}
				>
					<Row>
						<Col sm={3} xxs={6}>
							<FormField label={t('videobot:settingsPage.textColour')}>
								<FieldColor
									register={register}
									trigger={trigger}
									setValue={setValue}
									name="theme.textColor"
									defaultValue={data.theme?.textColor}
									required
								/>
							</FormField>
						</Col>
						<Col sm={3} xxs={6}>
							<FormField label={t('videobot:settingsPage.buttonBackgroundColour')}>
								<FieldColor
									register={register}
									trigger={trigger}
									setValue={setValue}
									name="theme.backgroundColor"
									defaultValue={data.theme?.backgroundColor}
									required
								/>
							</FormField>
						</Col>
						<Col sm={6} xxs={12}>
							<VideoBotButtonPreviewLabel>
								{t('videobot:settingsPage.buttonPreview')}
							</VideoBotButtonPreviewLabel>
							<VideoBotButtonPreview
								textColor={textColor}
								backgroundColor={buttonBackgroundColor}
								onClick={(event) => event.preventDefault()}
							>
								{t('videobot:settingsPage.buttonPreview')}
							</VideoBotButtonPreview>
						</Col>
					</Row>
				</FormRow>

				<FormRow
					title={t('videobot:settingsPage.gradientToggle')}
					description={t('videobot:settingsPage.gradientToggleDescription')}
				>
					<Row>
						<Col sm={6} xxs={12}>
							<ControlledFieldToggle
								control={control}
								name="theme.isGradientEnabled"
								defaultSelected={isGradientEnabled}
							/>
						</Col>
					</Row>
				</FormRow>
				{isCompanyProfileFlagged && (
					<FormRow
						title={t('videobot:settingsPage.companyProfileToggle')}
						description={t('videobot:settingsPage.companyProfileToggleDescription')}
					>
						<Row>
							<Col sm={6} xxs={12}>
								<ControlledFieldToggle
									control={control}
									name="isCompanyProfileEnabled"
								></ControlledFieldToggle>
							</Col>
						</Row>
					</FormRow>
				)}
				{isHideShareButtonFlagged && (
					<FormRow
						title={t('videobot:settingsPage.hideShareButtonToggle')}
						description={t('videobot:settingsPage.hideShareButtonToggleDescription')}
					>
						<Row>
							<Col sm={6} xxs={12}>
								<ControlledFieldToggle
									control={control}
									name="isShareDisabled"
								></ControlledFieldToggle>
							</Col>
						</Row>
					</FormRow>
				)}
				{isHideWatermarkFlagged && (
					<FormRow
						title={t('videobot:settingsPage.hideWatermarkToggle')}
						description={t('videobot:settingsPage.hideWatermarkToggleDescription')}
					>
						<Row>
							<Col sm={6} xxs={12}>
								<ControlledFieldToggle
									control={control}
									name="isWatermarkDisabled"
								></ControlledFieldToggle>
							</Col>
						</Row>
					</FormRow>
				)}
				<FormRow
					title={t('videobot:settingsPage.companyLogo')}
					description={t('videobot:settingsPage.companyLogoDescription')}
				>
					<FieldAvatar
						name="logoId"
						type="image"
						mediaType="LogoImage"
						valueName="logoId"
						setValue={setValue}
						defaultValue={data.logo?.url}
						accountId={data.accountId}
						label={t('videobot:settingsPage.companyLogo')}
					/>
				</FormRow>
				<FormRow
					title={t('videobot:settingsPage.tags')}
					description={t('videobot:settingsPage.tagsDescription')}
				>
					<div className="w-full">
						<TagPicker
							data={tagOptions}
							className="w-1/2"
							creatable
							defaultValue={defaultTagValues}
							onCreate={(value, item) => addNewTag(item)}
							onChange={(values) => {
								setValue(
									'tagsList',
									values.map((value) => ({ name: value })),
									{ shouldDirty: true },
								)
							}}
							onSearch={(value) => setSearchQuery(value)}
							value={defaultTagValues}
						/>
					</div>
					<div>
						<div
							className="mb-3 mt-3 cursor-pointer text-primary"
							onClick={() => setIsAddingTag(!isAddingTag)}
						>
							Add New Tag
						</div>

						{isAddingTag && (
							<div className="grid grid-cols-[80%_20%] gap-1">
								<FieldText
									name="newTagName"
									register={register}
									setValue={setValue}
									placeholder={t('widget:fields.newTagName.label')}
									type="text"
									required
								/>
								<Button size="default" variant="secondary" onClick={addNewTag}>
									Add
								</Button>
							</div>
						)}
					</div>
				</FormRow>

				<FormRow
					title={t('videobot:settingsPage.captions')}
					description={t('videobot:settingsPage.captionsDescription')}
				>
					<Row>
						<Col sm={6} xxs={12}>
							<ControlledFieldToggle control={control} name="isCaptionEnabled" />
						</Col>
					</Row>

					<Row>
						<Col sm={6} xxs={12}>
							<FormField label={t('videobot:settingsPage.captionLanguage')}>
								<FieldSelect
									register={register}
									placeholder={t('videobot:settingsPage.captionLanguage')}
									name="language"
									type="text"
									error={errors.language}
									options={LANGS.map((item) => ({
										value: item.code,
										label: item.name,
										item,
									}))}
									setValue={setValue}
									defaultValue={data.language}
								/>
							</FormField>
						</Col>
					</Row>
				</FormRow>
				<FormRow
					title={t('videobot:settingsPage.arrowButton')}
					description={t('videobot:settingsPage.arrowButtonDescription')}
				>
					<Row>
						<Col lg={4} sm={6} xxs={12}>
							<ControlledFieldToggle
								control={control}
								name="theme.isArrowButtonEnabled"
							/>
						</Col>
					</Row>
					<Row>
						<Col lg={4} sm={6} xxs={12}>
							<FormField
								label={t('videobot:botFields.delayButtons.label')}
								tooltip={t('videobot:botFields.delayButtons.tooltip')}
							>
								<FieldText
									register={register}
									placeholder={t('videobot:botFields.delayButtons.placeholder')}
									name="theme.delayButtons"
									type="number"
								/>
							</FormField>
						</Col>
					</Row>
				</FormRow>
				<FormRow
					title={t('videobot:settingsPage.backButtonIntro')}
					description={t('videobot:settingsPage.backButtonIntroDescription')}
				>
					<Row>
						<Col lg={4} sm={6} xxs={12}>
							<ControlledFieldToggle control={control} name="theme.backButtonIntro" />
						</Col>
					</Row>
				</FormRow>
			</FormSection>

			<FormSection
				title={t('videobot:settingsPage.trackingAnalytics')}
				description={t('videobot:settingsPage.trackingAnalyticsDescription')}
			>
				<FormRow
					title={t('videobot:settingsPage.videobotAnalytics')}
					description={t('videobot:settingsPage.videobotAnalyticsDescription')}
				>
					<Row>
						<Col sm={6} xxs={12}>
							{accountData?.options?.disableAnalytics ? (
								<FieldToggle
									isDisabled
									error={{
										message: t(
											'videobot:settingsPage.videobotAnalyticsDisabled',
										),
									}}
								/>
							) : (
								<ControlledFieldToggle control={control} name="isAnalyticEnabled" />
							)}
						</Col>
					</Row>
				</FormRow>
				<FormRow
					title={t('videobot:settingsPage.analyticsIframeMessage')}
					description={t('videobot:settingsPage.analyticsIframeMessageDescription')}
				>
					<Row>
						<Col sm={6} xxs={12}>
							<ControlledFieldToggle
								control={control}
								name="analyticsIframeMessage"
							/>
						</Col>
					</Row>
				</FormRow>
				<FormRow
					title={t('videobot:settingsPage.gaAnalytics')}
					description={
						<Trans i18nKey="videobot:settingsPage.gaAnalyticsDescription">
							Enable and define your Google tracking IDs.
							<a href="/documents/Installation_guide.pdf" target="_blank">
								Learn how to configure tracking on your website with our guide (pdf,{' '}
								110KB)
							</a>
						</Trans>
					}
				>
					<Row>
						<Col sm={6} xxs={12}>
							<ControlledFieldToggle
								control={control}
								name="isGoogleTrackingEnabled"
							/>
						</Col>
					</Row>
					{isGoogleTrackingEnabled && (
						<Row>
							<Col lg={4} sm={6} xxs={12}>
								<FormField label={t('videobot:botFields.ga4Id.label')}>
									<FieldText
										register={register}
										placeholder={t('videobot:botFields.ga4Id.placeholder')}
										name="googleTrackingSettings.ga4Id"
										type="text"
									/>
								</FormField>
							</Col>
							<Col lg={4} sm={6} xxs={12}>
								<FormField label={t('videobot:botFields.gtmId.label')}>
									<FieldText
										register={register}
										placeholder={t('videobot:botFields.gtmId.placeholder')}
										name="googleTrackingSettings.gtmId"
										type="text"
									/>
								</FormField>
							</Col>
						</Row>
					)}
					{isGoogleTrackingEnabled && (
						<Row>
							<Col lg={4} sm={6} xxs={12}>
								<FormField label={t('videobot:botFields.gaAdStorage.label')}>
									<div>
										<ControlledFieldToggle
											control={control}
											name="googleTrackingSettings.adStorage"
											values={GAConsentGrantedMode}
										>
											{({ isSelected }) =>
												capitalize(
													isSelected
														? GAConsentMode.granted
														: GAConsentMode.denied,
												)
											}
										</ControlledFieldToggle>
									</div>
								</FormField>
							</Col>
							<Col lg={4} sm={6} xxs={12}>
								<FormField label={t('videobot:botFields.gaAnalyticsStorage.label')}>
									<div>
										<ControlledFieldToggle
											control={control}
											name="googleTrackingSettings.analyticsStorage"
											values={GAConsentGrantedMode}
										>
											{({ isSelected }) =>
												capitalize(
													isSelected
														? GAConsentMode.granted
														: GAConsentMode.denied,
												)
											}
										</ControlledFieldToggle>
									</div>
								</FormField>
							</Col>
							<Col lg={4} sm={6} xxs={12}>
								<FormField
									label={t('videobot:botFields.gaFunctionalityStorage.label')}
								>
									<div>
										<ControlledFieldToggle
											control={control}
											name="googleTrackingSettings.functionalityStorage"
											values={GAConsentGrantedMode}
										>
											{({ isSelected }) =>
												capitalize(
													isSelected
														? GAConsentMode.granted
														: GAConsentMode.denied,
												)
											}
										</ControlledFieldToggle>
									</div>
								</FormField>
							</Col>
							<Col lg={4} sm={6} xxs={12}>
								<FormField
									label={t('videobot:botFields.gaPersonalizationStorage.label')}
								>
									<div>
										<ControlledFieldToggle
											control={control}
											name="googleTrackingSettings.personalizationStorage"
											values={GAConsentGrantedMode}
										>
											{({ isSelected }) =>
												capitalize(
													isSelected
														? GAConsentMode.granted
														: GAConsentMode.denied,
												)
											}
										</ControlledFieldToggle>
									</div>
								</FormField>
							</Col>
							<Col lg={4} sm={6} xxs={12}>
								<FormField label={t('videobot:botFields.gaSecurityStorage.label')}>
									<div>
										<ControlledFieldToggle
											control={control}
											name="googleTrackingSettings.securityStorage"
											values={GAConsentGrantedMode}
										>
											{({ isSelected }) =>
												capitalize(
													isSelected
														? GAConsentMode.granted
														: GAConsentMode.denied,
												)
											}
										</ControlledFieldToggle>
									</div>
								</FormField>
							</Col>
						</Row>
					)}
				</FormRow>
				<FormRow
					title={t('videobot:settingsPage.metaAnalytics')}
					description={t('videobot:settingsPage.metaAnalyticsDescription')}
				>
					<Row>
						<Col sm={6} xxs={12}>
							<ControlledFieldToggle
								control={control}
								name="metaTrackingSettings.enabled"
								defaultSelected={isMetaTrackingEnabled}
							/>
						</Col>
					</Row>
					{isMetaTrackingEnabled && (
						<Row>
							<Col lg={4} sm={6} xxs={12}>
								<FormField label={t('videobot:botFields.metaPixelId.label')}>
									<FieldText
										register={register}
										placeholder={t(
											'videobot:botFields.metaPixelId.placeholder',
										)}
										name="metaTrackingSettings.pixelId"
										type="text"
									/>
								</FormField>
							</Col>
						</Row>
					)}
				</FormRow>
			</FormSection>
			<FormSection
				title={t('videobot:settingsPage.sliderSettings')}
				description={t('videobot:settingsPage.sliderSettingsDescription')}
			>
				<FormRow
					title={t('videobot:settingsPage.widgetCoverImage')}
					description={t('videobot:settingsPage.widgetCoverImageDescription')}
				>
					<Row>
						<Col sm={6} xxs={12}>
							<ControlledFieldToggle
								control={control}
								name="isHorizontalCoverEnabled"
							>
								{t('videobot:settingsPage.widgetCoverImageHorizontal')}
							</ControlledFieldToggle>
						</Col>
					</Row>
					<FieldAvatar
						name="widgetCoverId"
						type="image"
						mediaType="CoverImage"
						valueName="widgetCoverId"
						setValue={setValue}
						defaultValue={data.widgetCover?.url}
						accountId={data.accountId}
						label={t('videobot:settingsPage.widgetCoverImage')}
						aspectRatio={aspectRatioSlider}
					/>
				</FormRow>

				<FormRow
					title={t('videobot:settingsPage.storyCoverImage')}
					description={t('videobot:settingsPage.storyCoverImageDescription')}
				>
					<FieldAvatar
						name="thumbnailCoverId"
						type="image"
						mediaType="CoverImage"
						valueName="thumbnailCoverId"
						setValue={setValue}
						defaultValue={data.thumbnailCover?.url}
						accountId={data.accountId}
						label={t('videobot:settingsPage.storyCoverImage')}
						aspectRatio={1}
					/>
				</FormRow>

				<FormRow
					title={t('videobot:settingsPage.storyTextDescription')}
					description={t('videobot:settingsPage.storyTextDescriptionInfo')}
				>
					<Row>
						<Col sm={3} xxs={6}>
							<FormField label={t('videobot:sharePage.text')}>
								<FieldText
									register={register}
									name="thumbnailDescription"
									type="text"
									error={errors.thumbnailDescription}
								/>
							</FormField>
						</Col>
					</Row>
					<Row>
						<Col sm={3} xxs={6}>
							<FormField label={t('videobot:settingsPage.textColour')}>
								<FieldColor
									register={register}
									trigger={trigger}
									setValue={setValue}
									name="thumbnailDescriptionColor"
									defaultValue={data.thumbnailDescriptionColor}
								/>
							</FormField>
						</Col>
					</Row>
				</FormRow>
			</FormSection>

			<FormSection
				title={t('videobot:settingsPage.advancedSettings')}
				description={t('videobot:settingsPage.advancedSettingsDescription')}
			>
				<FormRow
					title={t('videobot:settingsPage.customCss')}
					description={t('videobot:settingsPage.customCssDescription')}
				>
					<Row>
						<Col sm={12} xxs={12}>
							<TextArea
								rows={12}
								placeholder={CUSTOM_CSS_PLACEHOLDER}
								{...register('theme.stylesRaw')}
							/>
						</Col>
					</Row>
				</FormRow>
				{isUrlMapEnabled && (
					<FormRow
						title={t('videobot:settingsPage.urlMap')}
						description={t('videobot:settingsPage.urlMapDescription')}
					>
						<Row>
							<Col sm={6} xxs={12}>
								<ControlledFieldToggle
									control={control}
									name="urlMapsSettings.enabled"
								>
									URL Map Enabled
								</ControlledFieldToggle>
							</Col>
						</Row>
						<Row>
							<Col sm={6} xxs={12}>
								<ControlledFieldToggle
									control={control}
									name="urlMapsSettings.defaultBotDisabled"
								>
									Hide default bot
								</ControlledFieldToggle>
							</Col>
						</Row>
						{data.urlMapsSettings?.enabled && (
							<Fragment>
								{urlMapFields.map((item, index) => (
									<Row key={item.id}>
										<Col sm={5}>
											<FormField label={t('videobot:settingsPage.url')}>
												<FieldText
													register={register}
													placeholder={t('videobot:settingsPage.url')}
													name={`urlMapsList.${index}.url`}
													type="text"
												/>
											</FormField>
										</Col>
										<Col sm={5}>
											<FormField
												label={t('videobot:settingsPage.urlVideobot')}
											>
												<FieldSelect
													register={register}
													placeholder={t(
														'videobot:settingsPage.urlVideobot',
													)}
													name={`urlMapsList.${index}.botId`}
													type="text"
													error={errors.privacy}
													options={allBots.map((item) => ({
														value: `${item.id}`,
														label: item.name,
														item,
													}))}
													setValue={setValue}
													defaultValue={`${item.botId}`}
												/>
											</FormField>
										</Col>
										<Col className="flex items-center justify-center" sm={2}>
											<FormField label={'Delete'}>
												<Button
													variant="red"
													onClick={urlMapRemoveHandler(index)}
												>
													X
												</Button>
											</FormField>
										</Col>
									</Row>
								))}
								<Button onClick={urlMapAppendHandler}>Add URL</Button>
							</Fragment>
						)}
					</FormRow>
				)}
			</FormSection>
		</Fragment>
	)
}

export const VideobotSettingsForm = () => {
	const { id } = useParams()
	const activeAccount = useCurrentAccount()
	const { data: allBots } = useReadAllBots(
		{ account_id: activeAccount.id, size: 250 },
		{
			query: {
				select: (data) => data.records,
			},
		},
	)
	const { data, isLoading, isError } = useReadBotById(id)
	const { formState } = useVideobotEditor()

	if (data && allBots) {
		return (
			<React.Fragment>
				<VideobotSettingsFormPresentation videobot={data} allBots={allBots} />
				{formState.isSubmitting && <Loader cover isLoading />}
			</React.Fragment>
		)
	}

	return (
		<React.Fragment>
			{isLoading && <Loader cover />}
			{isError && <Error background="body" />}
		</React.Fragment>
	)
}
