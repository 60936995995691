import { Fragment, useCallback, useEffect, useState } from 'react'
import { subDays, format, isValid } from 'date-fns'
import { AnimatePresence } from 'framer-motion'
import { omit } from 'lodash-es'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import ExcelJS from 'exceljs'

import Button from '@/components/Button'
import FieldSelect from '@/components/FieldSelect'
import { AnalyticsFilter } from '@/features/analytics/components/AnalyticsFilter'
import { AnalyticsList } from '@/features/analytics/components/AnalyticsList'
import { AnalyticsSummary } from '@/features/analytics/components/AnalyticsSummary'
import VideobotPreviewPanel from '@/features/Videobot/components/VideobotPreviewPanel'
import {
	useReadAccountById,
	useReadAccountTotalAnalytics,
	useReadBotWidgetById,
	useReadPaginateBotAnalytics,
} from '@/api/videobot'
import { EventChannelType, RoleType } from '@/api/videobot.schemas'
import { AnalyticsSettingsModal } from '@/features/analytics/components/analytics_settings_modal.component'
import { RoleCheck } from '@/modules/session/auth.component'
import { useCurrentAccount } from '@/modules/session/auth.store'
import { AnalyticsSliderList } from '@/features/analytics/components/AnalyticsSliderList'
import { AnalyticsChart } from '@/features/analytics/components/analytics_chart'
import Icon from '@/components/Icon'

import View from '../../layout/View'

const AnalyticsContents = ({ analyticsDefaultChannel, analyticsDisplayFields }) => {
	const { t } = useTranslation(['analytics'])
	const navigate = useNavigate()
	const activeAccount = useCurrentAccount()
	const [searchParams, setSearchParams] = useSearchParams({
		page: '1',
		size: '10',
		from_date: format(subDays(new Date(), 29), 'yyyy-MM-dd'),
		to_date: format(new Date(), 'yyyy-MM-dd'),
	})
	const {
		register,
		watch,
		setValue,
		formState: { errors },
	} = useForm({
		mode: 'onBlur',
		shouldUnregister: false,
	})

	const fromDate = searchParams.get('from_date')
	const toDate = searchParams.get('to_date')
	const paginationSize = Number(searchParams.get('size')) || 10
	const paginationPage = Number(searchParams.get('page')) || 1
	const orderBy = searchParams.get('order_by')
	const orderDirection = searchParams.get('order_direction')

	const queryParams = {
		page: 1,
		size: 1000,
		from_date:
			fromDate && isValid(new Date(fromDate))
				? fromDate
				: format(subDays(new Date(), 29), 'yyyy-MM-dd'),
		to_date: toDate && isValid(new Date(toDate)) ? toDate : format(new Date(), 'yyyy-MM-dd'),
		channel: searchParams.get('channel') || analyticsDefaultChannel || EventChannelType.widget,
		bot_ids: searchParams.get('bot_ids'),
		user_ids: searchParams.get('user_ids'),
		slider_ids: searchParams.get('slider_ids'),
	}

	const params = { ...queryParams, paginationSize, paginationPage, orderBy, orderDirection }

	// backward analytics supports
	// FIXME: Remove this when users won't fetch old analytics
	const hasSessions = new Date(fromDate) >= new Date('2023-07-03')
	const newCtrFormula = new Date(fromDate) >= new Date('2024-09-04')

	const [isSettingModalActive, setIsSettingModalActive] = useState(false)

	const isAllowedSettings =
		activeAccount?.role === RoleType.Super_Admin || activeAccount?.role === RoleType.Owner

	const [botPreview, setBotPreview] = useState(null)

	const { data: botsData, isLoading: isBotsLoading } = useReadPaginateBotAnalytics({
		account_id: activeAccount.id,
		...queryParams,
		bot_ids: queryParams.channel === EventChannelType.slider ? null : queryParams.bot_ids,
		slider_ids: queryParams.channel === EventChannelType.slider ? queryParams.slider_ids : null,
	})
	const { data: totalData, isLoading: isTotalLoading } = useReadAccountTotalAnalytics(
		activeAccount.id,
		{
			...queryParams,
			bot_ids: queryParams.channel === EventChannelType.slider ? null : queryParams.bot_ids,
			slider_ids:
				queryParams.channel === EventChannelType.slider ? queryParams.slider_ids : null,
		},
	)
	const { data: videobotPreview } = useReadBotWidgetById(botPreview?.id, undefined, {
		query: { enabled: Boolean(botPreview) },
	})

	const onPageChange = useCallback(
		(newPage) => {
			setSearchParams(
				(prev) => {
					prev.set('page', newPage)
					return prev
				},
				{ replace: true },
			)
		},
		[setSearchParams],
	)

	const onSort = useCallback(
		(orderBy, orderDirection) => {
			setSearchParams(
				(prev) => {
					if (orderBy.id) {
						prev.set('order_by', orderBy.id)
						prev.set('order_direction', orderDirection)
						prev.set('page', 1)
					}
					return prev
				},
				{ replace: true },
			)
		},
		[setSearchParams],
	)

	const [isReportLoading, setIsReportLoading] = useState(false)
	const [selectedFormat, setSelectedFormat] = useState('PDF')

	useEffect(() => {
		const subscription = watch((value, { name }) => {
			if (name === 'format') {
				setSelectedFormat(value.format)
			}
		})
		return () => subscription.unsubscribe()
	}, [watch])

	function convertToCSV(data) {
		if (!data || !data.length) return ''

		const delimiter = ';'
		const formattedData = data.map((record) => {
			return {
				videobotName: record.bot.name,
				...omit(record, 'bot', 'id'),
			}
		})

		const header = Object.keys(formattedData[0]).join(delimiter)
		const rows = formattedData
			.map((row) =>
				Object.values(row)
					.map((value) =>
						typeof value === 'string' && value.includes(delimiter)
							? `"${value}"`
							: value,
					)
					.join(delimiter),
			)
			.join('\n')

		const filterDetails = [
			`"Date Range: ${params.from_date} to ${params.to_date}"`,
			`"Channel: ${params.channel}"`,
			params.bot_ids ? `"Bot IDs: ${params.bot_ids}"` : '"All Bots"',
			params.user_ids ? `"User IDs: ${params.user_ids}"` : '"All Users"',
			'',
		].join(delimiter)

		return `${filterDetails}\n${header}\n${rows}`
	}

	async function downloadExcel(data) {
		if (!data || !data.length) return

		const workbook = new ExcelJS.Workbook()
		const worksheet = workbook.addWorksheet('Report')

		const filterDetails = [
			`Date Range: ${params.from_date} to ${params.to_date}`,
			`Channel: ${params.channel}`,
			params.bot_ids ? `Bot IDs: ${params.bot_ids}` : 'All Bots',
			params.user_ids ? `User IDs: ${params.user_ids}` : 'All Users',
		]

		filterDetails.forEach((detail) => {
			worksheet.addRow([detail]).commit()
		})

		worksheet.addRow([])

		const formattedData = data.map((record) => ({
			videobotName: record.bot.name,
			...omit(record, 'bot', 'id'),
		}))

		const headers = Object.keys(formattedData[0])
		worksheet.addRow(headers)

		formattedData.forEach((row) => {
			const rowData = headers.map((header) => row[header])
			worksheet.addRow(rowData)
		})

		workbook.created = new Date()
		workbook.modified = new Date()

		const startDate = params.from_date
		const endDate = params.to_date
		const fileName = `analytics_data_${startDate}_to_${endDate}.xlsx`

		const buffer = await workbook.xlsx.writeBuffer()

		const blob = new Blob([buffer], {
			type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		})
		const url = window.URL.createObjectURL(blob)
		const anchor = document.createElement('a')
		anchor.href = url
		anchor.download = fileName
		document.body.appendChild(anchor)
		anchor.click()

		window.URL.revokeObjectURL(url)
		document.body.removeChild(anchor)
	}

	const handleExcelDownload = async () => {
		if (!botsData?.records) return

		await downloadExcel(botsData.records)
	}

	const handleCSVDownload = () => {
		if (!botsData?.records) return

		const csvContent = convertToCSV(botsData.records)

		const startDate = params.from_date
		const endDate = params.to_date
		const fileName = `analytics_data_${startDate}_to_${endDate}.csv`

		const BOM = '\uFEFF'
		const blob = new Blob([BOM + csvContent], { type: 'text/csv;charset=utf-8;' })

		const url = window.URL.createObjectURL(blob)
		const a = document.createElement('a')
		a.href = url
		a.download = fileName
		a.click()
		window.URL.revokeObjectURL(url)
	}

	const handlePDFDownload = () => {
		setIsReportLoading(true)

		for (const [key, value] of Object.entries(params)) {
			if (value) {
				searchParams.set(key, value)
			}
		}

		const iframe = document.createElement('iframe')

		iframe.src = `/analytics-report/${params.channel}?${searchParams.toString()}`
		iframe.style.height = '0'
		iframe.style.width = '1280px'
		iframe.style.visibility = 'hidden'
		iframe.style.position = 'absolute'

		document.querySelector('body').appendChild(iframe)

		const handleAfterPrint = () => {
			const doc = iframe.contentWindow || iframe

			doc.removeEventListener('afterprint', handleAfterPrint)
			iframe.remove()
		}

		const handleMessage = (e) => {
			if (e.data.action === 'REPORT_READY') {
				const doc = iframe.contentWindow || iframe

				const style = doc.document.createElement('style')
				style.textContent = `
					@page {
						margin: 2.54cm;
					}
					body {
						margin: 0;
						padding: 0;
						box-sizing: border-box;
					}
				`
				doc.document.head.appendChild(style)

				doc.addEventListener('afterprint', handleAfterPrint)
				doc.focus()
				doc.print()

				window.removeEventListener('message', handleMessage)

				setIsReportLoading(false)
			}
		}

		window.addEventListener('message', handleMessage)
	}

	const handleReportDownload = () => {
		switch (selectedFormat) {
			case 'CSV':
				handleCSVDownload()
				break
			case 'XLSX':
				handleExcelDownload()
				break
			case 'PDF':
			default:
				handlePDFDownload()
				break
		}
	}

	const handleSliderReportDownload = () => {
		handlePDFDownload()
	}

	const exportTypes = [
		{
			name: 'PDF',
			label: t('analytics:formatPdf'),
		},
		{
			name: 'CSV',
			label: t('analytics:formatCsv'),
		},
		{
			name: 'XLSX',
			label: t('analytics:formatXlsx'),
		},
	]

	const isSliderAnalytics = params.channel === EventChannelType.slider

	return (
		<View
			header
			title={t('analytics')}
			actions={
				<div className="flex items-center justify-between space-x-2">
					{isAllowedSettings && (
						<Button
							variant="secondary"
							iconOnly
							onClick={() => setIsSettingModalActive(true)}
						>
							<Icon name="settings-2" />
						</Button>
					)}

					{!isSliderAnalytics && (
						<Fragment>
							<div className="w-28">
								<FieldSelect
									className="w-28"
									register={register}
									placeholder={t('analytics:chooseFormat')}
									name="format"
									type="text"
									defaultValue={'PDF'}
									required
									options={exportTypes.map((item) => ({
										value: item.name,
										label: item.label,
										item,
									}))}
									setValue={setValue}
									error={errors.status}
								/>
							</div>

							<Button
								isLoading={isReportLoading}
								onClick={handleReportDownload}
								disabled={activeAccount?.role === RoleType.Member}
								tooltip={
									activeAccount?.role === RoleType.Member
										? t('analytics:noPermissionTooltip')
										: undefined
								}
							>
								{t('analytics:downloadReport')}
							</Button>
						</Fragment>
					)}

					{isSliderAnalytics && (
						<Button isLoading={isReportLoading} onClick={handleSliderReportDownload}>
							{t('analytics:downloadReport')}
						</Button>
					)}
				</div>
			}
			mobileTopBar
			isLoading={isBotsLoading || isTotalLoading}
		>
			<AnalyticsFilter
				params={params}
				onBotsChange={(payload) => {
					setSearchParams((params) => ({
						...Object.fromEntries(params),
						bot_ids: payload.join(','),
					}))
				}}
				onUsersChange={(payload) =>
					setSearchParams((params) => ({
						...Object.fromEntries(params),
						user_ids: payload.join(','),
					}))
				}
				onDateRangeChange={(payload) => {
					setSearchParams((params) => ({
						...Object.fromEntries(params),
						from_date: payload[0],
						to_date: payload[1],
					}))
				}}
				onSlidersChange={(payload) => {
					setSearchParams((params) => ({
						...Object.fromEntries(params),
						slider_ids: payload.filter(Boolean).join(','),
					}))
				}}
				onChannelChange={(payload) => {
					setSearchParams((params) => ({
						...Object.fromEntries(params),
						channel: payload,
					}))
				}}
			/>
			{!hasSessions && (
				<div className="mb-4 border-l-4 border-l-red bg-red-light p-4 text-global">
					<span className="font-semibold">CTR%</span> calculation has been changed since{' '}
					<span className="font-semibold">2023-07-03</span>. Please filter by date after{' '}
					<span className="font-semibold">2023-07-03</span> to see the new CTR%.
				</div>
			)}
			{!newCtrFormula && (
				<div className="mb-4 border-l-4 border-l-red bg-red-light p-4 text-global">
					<span className="font-semibold">CTR</span> and{' '}
					<span className="font-semibold">Engagement Rate</span> calculation has been
					changed since <span className="font-semibold">2024-09-04</span>. Please filter
					by date after <span className="font-semibold">2024-09-04</span> to see the new
					metrics.
				</div>
			)}
			{isSettingModalActive && (
				<AnalyticsSettingsModal
					defaultChannel={analyticsDefaultChannel}
					displayFields={analyticsDisplayFields}
					accountId={activeAccount.id}
					onClose={() => setIsSettingModalActive(false)}
				/>
			)}
			<AnalyticsChart
				channel={params.channel}
				fromDate={params.from_date}
				toDate={params.to_date}
				userIds={params.user_ids}
				sliderIds={params.slider_ids}
				botIds={params.bot_ids}
			/>
			{totalData && (
				<AnalyticsSummary
					data={totalData}
					channel={params.channel}
					analyticsDisplayFields={analyticsDisplayFields}
					hasSessions={hasSessions}
					newCtrFormula={newCtrFormula}
				/>
			)}
			{botsData && !isSliderAnalytics && (
				<AnalyticsList
					data={botsData}
					onBotEdit={(bot) =>
						navigate(`/dashboard/videobots/${bot.id}`, {
							state: { prev: '/dashboard/analytics' },
						})
					}
					onBotPreview={(bot) => {
						setBotPreview(bot)
					}}
					params={params}
					channel={params.channel}
					hasSessions={hasSessions}
					newCtrFormula={newCtrFormula}
					analyticsDisplayFields={analyticsDisplayFields}
					onPageChange={onPageChange}
					onSort={onSort}
				/>
			)}
			{isSliderAnalytics && (
				<AnalyticsSliderList
					params={params}
					hasSessions={hasSessions}
					newCtrFormula={newCtrFormula}
					analyticsDisplayFields={analyticsDisplayFields}
					onBotEdit={(bot) =>
						navigate(`/dashboard/videobots/${bot.id}`, {
							state: { prev: '/dashboard/analytics' },
						})
					}
					onBotPreview={(bot) => {
						setBotPreview(bot)
					}}
					onPageChange={onPageChange}
					onSort={onSort}
				/>
			)}
			<AnimatePresence>
				{botPreview && videobotPreview && (
					<VideobotPreviewPanel
						bot={botPreview}
						onClose={() => setBotPreview(null)}
						videobot={videobotPreview}
					/>
				)}
			</AnimatePresence>
		</View>
	)
}

const AnalyticsLoader = () => {
	const { t } = useTranslation(['analytics'])

	const activeAccount = useCurrentAccount()
	const { data } = useReadAccountById(activeAccount.id)

	if (!data) {
		return <View header title={t('analytics')} mobileTopBar isLoading={!data} />
	}

	return (
		<AnalyticsContents
			analyticsDefaultChannel={data.analyticsDefaultChannel}
			analyticsDisplayFields={data.analyticsDisplayFields}
		/>
	)
}

export const AnalyticsPage = () => {
	return (
		<RoleCheck
			roles={[
				RoleType.Super_Admin,
				RoleType.Reseller,
				RoleType.Owner,
				RoleType.Member,
				RoleType.Admin,
			]}
		>
			<AnalyticsLoader />
		</RoleCheck>
	)
}
